/* eslint-disable react/prop-types */
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

function SearchField({ onPressEnter, defaultValue = '', className }) {
    const [value, setValue] = useState('');
    const handelKeyUpEvent = (event) => {
        if (event.key === 'Enter' && event.keyCode === 13) {
            onPressEnter(value);
        }
    };

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <div className={`relative w-72 ${className}`}>
            <input
                type="text"
                id="input-group-1"
                className="bg-gray-50 border border-grey-600 text-sm focus:ring-primary-800 focus:outline-primary-800 rounded-full block w-full pr-10 p-2.5 "
                placeholder="Cerca.."
                onKeyDown={handelKeyUpEvent}
                onChange={(e) => setValue(e.target.value)}
                value={value}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </div>
        </div>
    );
}

export default SearchField;
