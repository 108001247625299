/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { MESSAGES } from '../../constants';
import { getItersDropdown } from '../../helper/services/apis/common-services';

function IterDropdown({
    register,
    name,
    config = {},
    errors,
    className = null,
    defaultValue,
    setValue,
    label,
    disabled = false
}) {
    const [options, setOptions] = useState([]);

    const initValues = async () => {
        const result = await getItersDropdown().catch((e) => console.log(e.message));

        if (result.data) {
            setOptions([...result.data]);
        }
    };

    const handleChange = (event) => {
        if (event && event.length) {
            setValue(name, event);
        } else {
            setValue(name, '');
        }
    };

    useEffect(() => {
        initValues();
        if (setValue) {
            handleChange(defaultValue);
        }
    }, [defaultValue]);

    return (
        <div
            className={`w-full flex items-center space-x-2 input-field-group ${className} ${
                errors[name] ? 'invalid-field' : ''
            }`}>
            <div className="w-1/3">
                <label htmlFor="iter">{config.required ? `${label}*` : label} : </label>
            </div>
            <div className="w-2/3">
                {options.length ? (
                    <select
                        id="iter"
                        name="iter"
                        {...register(name, { ...config })}
                        disabled={!!disabled}>
                        <option value="">Seleziona...</option>
                        {options.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                ) : null}

                {errors[name]?.type === 'required' && (
                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                )}
            </div>
        </div>
    );
}

export default IterDropdown;
