/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Storage from '../../helper/services/common/storage-helper-services';

function Header({ className, children }) {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const data = Storage.get('profile');
        console.log(data);
        setUser(JSON.parse(data));
    }, []);

    useEffect(() => {
        console.log(user);
    }, [user]);

    return (
        <header
            className={`flex justify-between space-y-2 flex-row-reverse md:items-center p-4 h-[72px] bg-white shadow-md ${className}`}>
            <div className="flex justify-between md:justify-start md:space-x-2 ">
                {children}
                <div className="relative inline-block">
                    <Link to={`./profile/${user?.id}`} className="flex flex-row items-center pl-4">
                        <div>
                            <div className="text-xs font-bold text-end">
                                <b>{user?.userName}</b>
                            </div>
                            <div className="text-xs text-grey-700 text-end">{user?.email}</div>
                        </div>
                    </Link>
                </div>
            </div>
        </header>
    );
}

export default Header;
