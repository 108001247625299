/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MESSAGES } from '../constants';

const functionHandler = () => {
    console.log('Call function');
};

function RatingComponent({
    register = functionHandler,
    name,
    config = {},
    errors,
    defaultValue = 0,
    setValue = functionHandler,
    count = 5,
    readOnly = false,
    disabled
}) {
    const [currentValue, setCurrentValue] = useState(defaultValue);

    const onClickHandler = (index, value) => {
        if (!readOnly) {
            if (index === value) {
                setCurrentValue(0);
            } else {
                setCurrentValue(index);
            }
        }
    };

    const handleChange = (value) => {
        if (value > count) {
            toast.error('Invalid default value');
        } else {
            setValue(name, value);
        }
    };

    const renderStars = (startCount, value) => {
        const stars = [];
        for (let index = 1; index <= startCount; index++) {
            stars.push(
                <svg
                    aria-hidden="true"
                    className={`transition w-7 h-7${
                        !(disabled || readOnly) ? ' hover:w-8 hover:h-8 hover:cursor-pointer ' : ''
                    }  ${index <= value ? 'text-yellow-400' : 'text-gray-300'} ${
                        disabled ? 'opacity-50' : ''
                    }`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => onClickHandler(index, value)}>
                    <title>{index}</title>
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
            );
        }
        return stars;
    };

    useEffect(() => {
        handleChange(currentValue);
    }, [currentValue]);

    useEffect(() => {
        setCurrentValue(defaultValue);
    }, [defaultValue]);

    return (
        <div>
            <div className="flex items-center">{renderStars(count, currentValue)}</div>
            {!(disabled || readOnly) ? (
                <>
                    <input type="hidden" {...register(name, { ...config })} />
                    {errors?.[name]?.type === 'required' && (
                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                    )}
                </>
            ) : null}
        </div>
    );
}

export default RatingComponent;
