/* eslint-disable react/prop-types */
import React from 'react';

function AppHeader({ children, className }) {
    return (
        <div
            className={`flex flex-col justify-between space-y-0 md:flex-row md:items-center  ${className}`}>
            {children}
        </div>
    );
}

export default AppHeader;
