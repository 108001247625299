/* eslint-disable react/prop-types */
import React from 'react';

function ModalComponent({ isOpen = false, title, closeModel, children, size = 'max-w-xl' }) {
    return (
        // Main modal
        <div
            id="defaultModal"
            tabIndex="-1"
            aria-hidden="true"
            className={`items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full ${
                isOpen ? 'flex justify-center bg-[#1b1b1baf]' : 'hidden'
            }`}>
            <div className={`relative p-4 w-full h-full ${size} max-h-[80vh] md:h-auto`}>
                {/* Modal content  */}
                <div className="relative bg-white rounded-lg shadow transition-all translate-y">
                    {/* Modal header  */}
                    <div className="flex items-start p-4 rounded-t bg-primary-500">
                        <h3 className="text-xl font-semibold text-white text-center w-full">
                            {title}
                        </h3>
                        <button
                            type="button"
                            className="text-white bg-transparent hover:bg-primary-700 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-toggle="defaultModal"
                            onClick={closeModel}>
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    {children}
                    {/* Modal footer */}
                    {/* <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200">
                        <button
                            data-modal-toggle="defaultModal"
                            type="button"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            I accept
                        </button>
                        <button
                            data-modal-toggle="defaultModal"
                            type="button"
                            className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                            Decline
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default ModalComponent;
