/* eslint-disable react/prop-types */
import React from 'react';

function HeaderItem({ children, className }) {
    return (
        <div className={`flex justify-between md:justify-start md:space-x-2 ${className}`}>
            {children}
        </div>
    );
}

export default HeaderItem;
