/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../../config/axios.config';

export const getTableData = async (path) => {
    const result = await axiosInstance.get(`v1/${path}`);
    return result;
};

export const createNewTableValue = async (path, payload) => {
    const result = await axiosInstance.post(`v1/${path}`, payload);
    return result;
};

export const updateNewTableValue = async (path, id, payload) => {
    const result = await axiosInstance.put(`v1/${path}/${id}`, payload);
    return result;
};

export const deleteTableValue = async (path, id) => {
    const result = await axiosInstance.delete(`v1/${path}/${id}`);
    return result;
};
