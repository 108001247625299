/* eslint-disable react/prop-types */
import React from 'react';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';

function ConfirmationModal({
    isOpen = false,
    onConfirmAction = null,
    title,
    message,
    closeModel = null,
    confirmBtnText = 'CONTINUA',
    cancelBtnText = 'ANNULLA'
}) {
    const onSubmitHandler = () => {
        onConfirmAction();
    };

    return (
        <ModalComponent isOpen={isOpen} closeModel={() => closeModel(false)} title={title}>
            {/* Modal Body */}
            <div className="p-6 space-y-6 text-center">
                <b>{message}</b>
            </div>

            {/* Modal Footer */}
            <div className="flex justify-center p-6 space-x-4 rounded-b bg-white">
                <SecondaryButton onClick={() => closeModel(false)}>{cancelBtnText}</SecondaryButton>
                <PrimaryButton onClick={onSubmitHandler}>{confirmBtnText}</PrimaryButton>
            </div>
        </ModalComponent>
    );
}

export default ConfirmationModal;
