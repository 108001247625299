/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';

export const getActivitiesListData = async (queryString) => {
    const result = await axiosInstance.get(`v1/activities?${queryString}`);
    return result;
};

export const getActivitiesListCsvData = async (queryString) => {
    const result = await axiosInstance.get(`v1/activities-csv-data?${queryString}`);
    return result;
};

export const getActivityById = async (id) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.ACTIVITY}/${id}`);
    return result;
};

// export const getActivitysRolesDropdown = async () => {
//     const result = await axiosInstance.get(`v1/${API_ENDPOINT.ROLE}s-dropdown`);
//     return result;
// };

export const createNewActivity = async (payload) => {
    const result = await axiosInstance.post(`v1/${API_ENDPOINT.ACTIVITY}`, payload);
    return result;
};

export const updateActivity = async (id, payload) => {
    const result = await axiosInstance.put(`v1/${API_ENDPOINT.ACTIVITY}/${id}`, payload);
    return result;
};

export const updateActivityFields = async (id, payload) => {
    const result = await axiosInstance.patch(`v1/${API_ENDPOINT.ACTIVITY}/${id}`, payload);
    return result;
};

export const deleteActivity = async (id) => {
    const result = await axiosInstance.delete(`v1/${API_ENDPOINT.ACTIVITY}/${id}`);
    return result;
};
